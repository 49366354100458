
import Axios from "axios"
import moment from "moment"
import { DateTime } from "luxon";
import { API } from "../constants/api_url"
import { setPPFListData } from "../../RTK/Background/pendingData"
import APIServices from "../../service/APIService"




const renderMonthlyReport = (id, dispatch) => {


    let dcf_list = [], dcf_submitted = [], locloc = [], rawData = []




    const promise1 = APIServices.get(API.DCF)
    const promise2 = APIServices.get(API.DCF_Submit_UP(id))
    const promise3 = APIServices.get(API.LocationThree)


    Promise.all([promise1, promise2, promise3]).then(function (values) {
        dcf_list = values[0].data;

        dcf_submitted = values[1].data;
        locloc = values[2].data

        console.log(values)
        APIServices.get(API.AssignDCFUser_UP(id)).then((res) => {
            let filter = [], locationFilter = [0], pending = [], count = 0

            res.data.forEach((item, i) => {



                if (item.type === 0) {


                    if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                        item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]


                        item.start_date = moment.utc(item.start_date).toDate()
                        filter.push(item)
                        if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                            locationFilter.push(item.site[0])
                        }

                        rawData.push(...getMonths(moment(moment.utc(item.start_date).toDate()), moment.utc(moment.utc()), item.frequency, item, dcf_submitted, id).data)


                    }

                }
            })



            dispatch(setPPFListData(rawData))

        })

    })

}
const getMonths = (startDate, endDate, frequency, item, old, id) => {

    var betweenMonths = [];
    let type = 0, past_ = [], present_ = [], future_ = []




    if (startDate.diff(endDate.startOf('month'), 'days') === 0) {
        let endDate_ = startDate

        let endDate__ = moment(moment.utc())
        if (frequency === 1) {

            let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

            if (check.result) {

                future_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.add(1, 'month').format('MMM-YYYY'), overdue: 0, site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

            } else {
                if (check.data) {

                    future_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.add(1, 'month').format('MMM-YYYY'), overdue: 0, site: item.site, company_id: id, frequency })
                }

            }
        } else if (frequency === 6) {
            let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

            if (check.result) {


                past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: endDate__.diff(endDate__, 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
            } else {
                if (check.data) {

                    past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: id, frequency })
                }

            }


        }

    }
    else if (startDate.diff(endDate.startOf('month'), 'days') < 31) {
        var date = startDate

        // let endDate__ = moment('01.03.2023', 'DD.MM.YYYY')
        let endDate__ = moment(moment.utc())
        while (date <= endDate__) {

            betweenMonths.push(date.format('MMM-YYYY'));
            date.add(1, 'month');


        }

        if (frequency === 1) {
            console.log(splitArray(betweenMonths, 1), 'SP')
            splitArray(betweenMonths, 1).forEach((months, ind) => {
                console.log(moment(months[0]).month(), item.dcf_, ind)
                if (endDate__.month() === moment(months[0]).month() && endDate__.year() === moment(months[0]).year()) {
                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                    if (check.result) {


                        future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                    } else {
                        if (check.data) {
                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                        }

                    }


                } else {
                    if (endDate__.diff(moment(months[0]), 'months') === 1) {

                        // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {


                            present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {
                            console.log(check.data, '_past', moment(months[0]).format('MMM-YYYY'), item)

                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {

                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }
                    }
                }

            })
        } else if (frequency === 2) {

            splitArray(betweenMonths, 2).forEach((months) => {
                if (months.length === 2) {
                    if (endDate__.month() === moment(months[1]).month() && endDate__.year() === moment(months[1]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)
                        if (check.result) {

                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })


                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[1]), 'months') === 1) {

                            // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)
                            if (check.result) {

                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })


                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                            if (check.result) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 3) {

            splitArray(betweenMonths, 3).forEach((months) => {
                if (months.length === 3) {

                    if (endDate__.month() === moment(months[2]).month() && endDate__.year() === moment(months[2]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                        if (check.result) {

                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[2]), 'months') === 1) {

                            // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                            if (check.result) {
                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }



                        } else {

                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                            if (check.result) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 4) {
            splitArray(betweenMonths, 12).forEach((months) => {
                if (months.length === 12) {
                    if (endDate__.month() === moment(months[11]).month() && endDate__.year() === moment(months[11]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                        if (check.result) {

                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[11]), 'months') === 1) {

                            // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                            if (check.result) {

                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }

                            // } else {
                            //     let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                            //     if (check.result) {

                            //         past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            //     } else {
                            //         if (check.data) {
                            //             past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            //         }

                            //     }

                            // }
                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                            if (check.result) {

                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 5) {
            splitArray(betweenMonths, 6).forEach((months) => {
                if (months.length === 6) {
                    if (endDate__.month() === moment(months[5]).month() && endDate__.year() === moment(months[5]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                        if (check.result) {


                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[5]), 'months') === 1) {

                            // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                            if (check.result) {


                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                            if (check.result) {


                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 6) {
            splitArray(betweenMonths, 1).forEach((months, ind) => {
                if (moment(months[0]).month() === endDate__.month() && moment(months[0]).year() === endDate__.year()) {
                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                    if (check.result) {
                        present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency })

                        check.list.forEach((list) => {
                            present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                        })

                    } else {
                        if (check.data) {
                            present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency })

                        }

                    }


                } else {
                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                    if (check.result) {

                        check.list.forEach((list) => {
                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency, response: list.response, id: list.id })

                        })


                    } else {
                        if (check.data) {
                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency })

                        }

                    }


                }

            })
        }

    }
    console.log(past_)





    return { type, months: betweenMonths, data: [...past_, ...present_, ...future_] }
}

const checkSubmission = (dcf, site, rp, old) => {
    let rps = getRP(rp)
    let result = {}
    let loc = JSON.parse(JSON.stringify(old))

    let index = loc.findIndex((i) => {
        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 0 || i.type === 1 || i.type === 3)
    })
    let index2 = loc.findIndex((i) => {
        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 1 || i.type === 3)
    })

    if (index === -1) {

        result = { result: false, data: true }
    } else {
        result = {
            result: true, data: loc[index], list: loc.filter((i) => {
                return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 0 || i.type === 1 || i.type === 3)
            })
        }
    }

    return result
}
const compareArrays = (a, b) => {


    return JSON.stringify(a) === JSON.stringify(b);
};
const splitArray = (array, chunkSize) => {


    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
}
const getRP = (months) => {
    if (months.includes('to')) {
        let startDate = moment(months.split('to')[0].trim())
        let endDate = moment(months.split('to')[1].trim())
        let rp = []
        while (startDate <= endDate) {

            rp.push(startDate.format('MM-YYYY'));
            startDate.add(1, 'month');


        }
        return rp
    } else {
        return [moment(months).format('MM-YYYY')]
    }
}

const getLocationData = (locationData, roleAssignments, roles) => {
    // Filter role assignments based on roles
    const relevantAssignments = roleAssignments.filter(assignment =>
        assignment.roles.some(role => roles.includes(role))
    );

    // Initialize the result as an empty array
    let result = [];

    // Loop through each relevant assignment
    relevantAssignments.forEach(assignment => {
        if (assignment.tier1_id === 0) {
            // If tier1_id is 0, return all locations
            result = locationData;
        } else {
            // Find the matching tier1 (country)
            let country = locationData.find(loc => loc.id === assignment.tier1_id);
            if (!country) return;

            if (assignment.tier2_id === 0) {
                // If tier2_id is 0, return the country and its regions
                result.push(country);
            } else {
                // Find the matching tier2 (region)
                let region = country.locationTwos.find(loc => loc.id === assignment.tier2_id);
                if (!region) return;

                if (assignment.tier3_id === 0) {
                    // If tier3_id is 0, return the region and its sites
                    result.push({
                        ...country,
                        locationTwos: [region]
                    });
                } else {
                    // Find the matching tier3 (site)
                    let site = region.locationThrees.find(loc => loc.id === assignment.tier3_id);
                    if (!site) return;

                    result.push({
                        ...country,
                        locationTwos: [{
                            ...region,
                            locationThrees: [site]
                        }]
                    });
                }
            }
        }
    });

    // Remove duplicate locations from the result
    result = result.filter((item, index, self) =>
        index === self.findIndex(t => t.id === item.id)
    );

    return result;
}
function checkRoleAccessByRoleIds(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId) {
    // Step 1: Filter roleAssignments based on user_ids and roles
    const filteredAssignments = roleAssignments.filter(assignment =>
        user_ids.includes(assignment.user_id) &&
        assignment.roles.some(role => roles.includes(role))
    );

    // Step 2: Create a set of user IDs who have access
    const userIdsWithAccess = new Set();
    if (adminId) {
        userIdsWithAccess.add(adminId);
    }

    // Step 3: Check each filtered assignment for access
    filteredAssignments.forEach(assignment => {
        const { user_id, tier1_id, tier2_id, tier3_id } = assignment;

        // Get valid tier IDs for this specific assignment
        const validTierIds = getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData);

        if (level === 0) {
            // Global access
            userIdsWithAccess.add(user_id);
        } else if (level === 1 && validTierIds.countries.includes(tier_id) && !assignment.tier2_id) {
            // Country level access
            userIdsWithAccess.add(user_id);
        } else if (level === 2 && (validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id)) && !assignment.tier3_id) {
            // Region level access
            userIdsWithAccess.add(user_id);
        } else if (level === 3 && (validTierIds.businessUnits.includes(tier_id) || validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id))) {
            // Business Unit level access
            userIdsWithAccess.add(user_id);
        }
    });

    // Step 4: Return the list of user IDs who have access
    return Array.from(userIdsWithAccess);
}

function getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData) {
    let countries = new Set();
    let regions = new Set();
    let businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === country.id || tier1_id === 0 || tier1_id === null) {
            countries.add(country.id);
            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id || tier2_id === null) {
                    regions.add(region.id);
                    region.locationThrees.forEach(bu => {
                        if (tier3_id === 0 || tier3_id === bu.id || tier3_id === null) {
                            businessUnits.add(bu.id);
                        }
                    });
                }
            });
        }
    });

    return {
        countries: Array.from(countries),
        regions: Array.from(regions),
        businessUnits: Array.from(businessUnits)
    };
}

const filterSubmissionsByFiscalYear = (submissions, year, fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);

    return submissions.filter(submission => {
        const allDatesWithinRange = submission.reporting_period.every(period => {
            const periodDate = DateTime.fromFormat(period, 'MM-yyyy').startOf('month');
            return periodDate >= startDate && periodDate <= endDate;
        });

        return allDatesWithinRange;
    });
};



function groupArrayByKeys(dataArray, keys) {
    return dataArray.reduce((result, item) => {
        // Create a group key by concatenating the values of the specified keys
        const groupKey = keys.map(key => item[key]).join('-');

        // If the group key doesn't exist in the result, initialize it as an empty array
        if (!result[groupKey]) {
            result[groupKey] = [];
        }

        // Push the current item into the group
        result[groupKey].push(item);

        return result;
    }, {});
}
function removeDuplicatesFromArrayByKey(array, key) {
    const uniqueItems = new Map();

    return array.filter(item => {
        const keyValue = item[key];
        if (!uniqueItems.has(keyValue)) {
            uniqueItems.set(keyValue, true);
            return true;
        }
        return false;
    });
}
function getLocationDetailsByLevelLocationId(level, locationId, locationData) {
    if (level === 0) {
        return {
            Country: "All Countries",
            Region: "All Regions",
            "Business Unit": "All Business Unit",
        };
    }

    let result = {
        Country: "All Countries",
        Region: "All Regions",
        "Business Unit": "All Business Unit",
    };

    for (let country of locationData) {
        if (level === 1 && country.id === locationId) {
            result.Country = country.name;
            break;
        }

        if (country.locationTwos) {
            for (let region of country.locationTwos) {
                if (level === 2 && region.id === locationId) {
                    result.Country = country.name;
                    result.Region = region.name;
                    break;
                }

                if (region.locationThrees) {
                    for (let unit of region.locationThrees) {
                        if (level === 3 && unit.id === locationId) {
                            result.Country = country.name;
                            result.Region = region.name;
                            result["Business Unit"] = unit.name;
                            break;
                        }
                    }
                }
            }
        }
    }

    return result;
}
const getRPTextFormat = (item) => {
    if (item.length !== 0) {
        if (item.length >= 2) {
            const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                "LLL-yyyy"
            );
            const endDate = DateTime.fromFormat(
                item[item.length - 1],
                "MM-yyyy"
            ).toFormat("LLL-yyyy");
            return `${startDate} to ${endDate}`;
        } else {
            return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
        }
    }
};
function parseValue(value, type = 1, accept = 3) {
    if (value === null || value === undefined || (!isFinite(value) && typeof value !== 'string') || Number.isNaN(value)) {
        return { valid: false, value: null };
    }

    let cleanedValue = typeof value === 'string' ? value.replace(/[^0-9.-]/g, '') : value;
    let result = type === 2 ? Number(cleanedValue) : parseFloat(cleanedValue);

    if (Number.isNaN(result) || !isFinite(result) || !(result != null)) {
        return { valid: false, value: result };
    }
    if (typeof value === 'string') {
        console.log(/^[0-9]+(?:,[0-9]+)*(?:\.[0-9]+)?$/.test(value))

        if (!/^[0-9]+(?:,[0-9]+)*(?:\.[0-9]+)?$/.test(value)) {
            return { valid: false, value: result };
        }
    }
    if (type === 2 && !Number.isInteger(result)) {
        return { valid: false, value: result };
    }

    if (accept === 3 && result < 0) {
        return { valid: false, value: result };
    }

    let valid = false;

    if (accept === 1) {
        valid = result === 0 ? true : true;
    } else if (accept === 2) {
        valid = result > 0 ? true : false;
    } else {
        valid = true;
    }

    return { valid, value: result };
}
const generateFiscalYearsByAssignment = (assignments, fymonth) => {
    const fiscalYears = [];

    assignments.forEach(({ start_date, end_date }) => {
        const startDate = DateTime.fromISO(start_date, { zone: "utc" });
        const endDate = end_date
            ? DateTime.fromISO(end_date, { zone: "utc" })
            : DateTime.utc(); // Use current UTC date if end_date is null

        // Determine the fiscal year of the start date
        let startFY = startDate.year;
        if (fymonth !== 1 && startDate.month < fymonth) {
            startFY -= 1;
        }

        // Determine the fiscal year of the end date
        let endFY = endDate.year;
        if (fymonth !== 1 && endDate.month < fymonth) {
            endFY -= 1;
        }

        // Add all fiscal years in the range to the result
        for (let year = startFY; year <= endFY; year++) {
            const value = fymonth === 1 ? year : year + 1;
            const title = fymonth === 1
                ? `${year}`
                : `${year}-${String(year + 1).slice(-2)}`;

            // Avoid duplicates
            if (!fiscalYears.some(fy => fy.value === value)) {
                fiscalYears.push({ value, title });
            }
        }
    });

    // Sort fiscal years by value
    return fiscalYears.sort((a, b) => a.value - b.value);
}
const filterAssignmentsByFiscalYear = (assignments, year, fymonth=1) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);
    const currentDate = DateTime.local().startOf('day');

    return assignments.filter(assignment => {

        const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
        const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

        return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
            (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
            (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
    });
};
const getFiscalYearRange = (year, fymonth) => {
    let startDate, endDate;
    console.log(fymonth)

    if (fymonth === 1) {
        startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
    } else {
        startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
    }

    return { startDate, endDate };
};
export { renderMonthlyReport, getLocationData, checkRoleAccessByRoleIds, filterSubmissionsByFiscalYear, groupArrayByKeys, removeDuplicatesFromArrayByKey, getLocationDetailsByLevelLocationId, getRPTextFormat, parseValue,generateFiscalYearsByAssignment,filterAssignmentsByFiscalYear }